import React from 'react';

import Layout from '../components/layout';
import SEO from '../components/seo';

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <h1 className="title">Grafig IT Consultancy Services</h1>
    <hr />
    <section className="section">
      <div className="container">
        <h1 className="title">1. It's all about your business</h1>
        <h2 className="subtitle">Your business comes first, at all times.</h2>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <h1 className="title">2. Win-win or nothing</h1>
        <h2 className="subtitle">We are here to help, or we are gone.</h2>
      </div>
    </section>
    <section className="section">
      <div className="container">
        <h1 className="title">3. Trust</h1>
        <h2 className="subtitle">It is all about deep, long-lasting partnerships.</h2>
      </div>
    </section>
    <hr />
    <div className="content">
      <p>
        Grafig offers on-site digital consultancy services (in London) in the form of expert advice and software
        engineering to deliver outstanding ROI on your business investment.
      </p>
      <h2>Lean & Agile</h2>
      <p>
        Iterative, requirements-based MVPs form the core of our approach. We cut all the unnecessary distractions to
        bring your ideas to market in record times. Through deep collaboration, we deliver with <em>true</em> Agile and
        Lean methodologies even if your starting point is waterfall.
      </p>
      <h2>Digital Transformation</h2>
      <p>
        Complex systems are usually found due to historical decisions and fast growth in the past. That is perfectly
        normal, and you have reached the point where you know that you need to deliver more and faster. That is the
        perfect starting point to engage with us because we specialise in tidying up architectures and solving problems
        on conceptual levels, with a follow up on implementation. Our software engineering services are versatile: you
        are more than welcome to have a go at it doing it yourself, or choosing a hybrid approach where we integrate
        with your business for a short while where help is needed. Alternatively, you may prefer that we entirely deal
        with the innovation so your teams can focus on BAU. Apart from our recommendation based on your needs, it is all
        up to you!
      </p>
      <h2>Continuous Everything</h2>
      <p>
        We continually: learn, build, deploy, and deliver. When we work together, you know that we are an up to date,
        state of the art consultancy. First, we learn all about your business to gather a deep understanding of your
        teams and processes. Then, we promote building through communication and skills to deliver high quality services
        and products. Finally, deployments and delivery are promoted to become as continuous as possible. Shipping fast
        to customers usually translates in growth, plain and simple.
      </p>
      <h2>Sharing Success</h2>
      <p>
        When a successful solution comes to life, we celebrate your success as if it was ours. That is how proud we are
        about the work we do.
      </p>
    </div>
  </Layout>
);

export default IndexPage;
